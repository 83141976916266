<template>
  <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
    <!-- banner -->
    <div class="m_tb_20">
      <img :src="bannerImg" alt="">
    </div>
    <!-- 教材资源 -->
    <div class="content-box">
      <div class="title-box flex_row m_tb_20" style="justify-content:space-between;">
        <div class="flex_row">
          <img :src="jiaocaiImg" alt="">
          <div class="title-text fs_22 ">电子教材</div>
        </div>
        <div class="confirm-button fs_16 c_fff pointer" :class="{ 'confirm-button-select': selectedJiaocai }"
             @click="toDetails(2)">确认选择
        </div>
      </div>
      <div class="jiaocai-content-box">
        <!--  筛选目录  -->
        <div class="classify-box flex_col m_tb_20 p_in_10 br_5 p_lr_20" style="width:900px;">
          <div class="p_tb_10 fs_16 flex_row_wrap jiaocai-item-box" v-for="(parentItem, index) of jiaocaiCatalogueList"
               :key="index">
            <span class="fw_bold m_tb_5" style="color:#0BB085;position:absolute;left:-70px;">
              {{ parentItem.name }}
            </span>
            <div class="m_r_30 m_tb_5 classify-item pointer c_666"
                 :class="{ 'jiaocai-selected': jiaocaiSelects[index] == childItem.id }"
                 @click="jiaocaiClassifyItem_click(childItem.id, index)" v-for="(childItem) of parentItem.data"
                 :key="childItem.id">
              {{ childItem.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程资源 -->
    <div class="content-box">
      <div class="title-box flex_row m_tb_20" style="justify-content:space-between;">
        <div class="flex_row">
          <img :src="courseImg" alt="">
          <div class="title-text fs_22 ">教学指导</div>
        </div>
        <div class="confirm-button fs_16 c_fff pointer" :class="{ 'confirm-button-select': selectedCourse }"
             @click="toDetails(1)">确认选择
        </div>
      </div>
      <div class="course-content-box">
        <!--  筛选目录  -->
        <div class="flex_col m_tb_20 p_in_10 br_5 p_lr_20" style="width:800px;">
          <div class="p_tb_10 fs_16 flex_row_wrap course-item-box" v-for="(parentItem, index) of courseCatalogueList"
               v-show="parentItem.data.length > 0" :key="index">
            <span class="fw_bold m_tb_5" style="color:#009CE0; position:absolute; left:-70px;">
              {{ parentItem.name }}
            </span>
            <div class="m_r_30 m_tb_5 pointer c_666"
                 :class="{ 'course-selected': courseSelects[index] == childItem.id }"
                 @click="courseClassifyItem_click(childItem.id, index)" v-for="(childItem) of parentItem.data"
                 :key="childItem.id">
              {{ childItem.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 教学工具 -->
    <div class="content-box">
      <div class="title-box flex_row m_tb_20">
        <img :src="toolImg" alt="">
        <div class="title-text fs_22 ">教学工具</div>
      </div>
      <!-- 智汇云应用 -->
      <div class="tool-content-box">
        <div>
          <div class="tool-box-title fs_16 fw_bold m_b_10">工具推荐</div>
          <div class="tool-box flex_row_wrap m_b_20 box1">
            <div class="tool p_in_10 br_10 m_tb_10 bg-other br_10 pointer" style="position: relative;"
                 @click="toolDetail(0)">

              <img class="br_5" :src="yilingyiIcon" width="100px" alt="">
              <div class="fs_18 m_tb_5 fw_bold">课件工具</div>
              <div class="fs_14 tool-introduce-color">用于教师线上教学的课件资源工具，具备相关音视频、学科工具等资源，为教师线上教学备课提供服务。</div>

              <div class="fs_12 phone" style="color:#000;">服务电话：15202829578</div>
            </div>
            <div class="tool p_in_10 br_10 m_tb_10 bg-other br_10 pointer" style="position: relative;"
                 @click="toolDetail(1)" @mouseout="showCode=false">
              <img :src="nezhaCode" style="position: absolute;width: 190px;left: 120px;top:-130px"
                   v-show="showCode&&showCodeIndex==1"/>
              <img class="br_5" :src="xuexiIcon" width="100px" alt="">
              <div class="fs_18 m_tb_5 fw_bold">智慧体育</div>
              <div class="fs_14 tool-introduce-color">
                用于疫情期间居家学生体育锻炼的任务发布，具备动作分析、数据汇总和实时反馈等功能。为体育教师“线上大课间”、“线上体育课”提供服务。
              </div>
              <div class="fs_12 phone" style="color:#000;">服务电话：18398261859</div>
            </div>
            <div class="tool p_in_10 br_10 m_tb_10 bg-other br_10 pointer" style="position: relative;"
                 @click="toolDetail(2)" @mouseout="showCode=false">
              <img :src="chaxunCode" style="position: absolute;width: 190px;left: 120px;top:-130px"
                   v-show="showCode&&showCodeIndex==2"/>
              <img class="br_5" :src="luping" width="100px" alt="">
              <div class="fs_18 m_tb_5 fw_bold">极简问卷/信息收集</div>
              <div class="fs_14 tool-introduce-color">
                实现教育系统内各级单位数据的上报、汇总、查看和分析，以便及时了解情况，并通过对上报数据的分析辅助其决策的信息化采集工具。
              </div>
              <div class="fs_12 phone" style="color:#000;">服务电话：17790275292</div>
            </div>
            <div class="tool p_in_10 br_10 m_tb_10 bg-other br_10 pointer" style="position: relative;"
                 @click="toolDetail(3)" @mouseout="showCode=false">
              <img :src="chaxunCode" style="position: absolute;width: 190px;left: 120px;top:-130px"
                   v-show="showCode&&showCodeIndex==3"/>
              <img class="br_5" :src="jxfk" width="100px" alt="">
              <div class="fs_18 m_tb_5 fw_bold">教学反馈</div>
              <div class=" fs_14 tool-introduce-color">
                老师基于语文，英语和奖赏类作业，支持上传文档、图片、视频(PC端上传)、音频(PC端上传)等教学资料，提供快速评语和留痕批阅，实时查看完成情况，作业结果及时反馈。
              </div>
              <div class="fs_12 phone" style="color:#000;">服务电话：17828866892</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tool-content-box">
        <div v-for="(tools, index) in toolCatalogueList" :key="index" v-show="tools.data.length > 0">
          <div class="tool-box-title fs_16 fw_bold m_b_10">{{ tools.name }}</div>
          <div class="tool-box flex_row_wrap m_b_20">
            <div class="tool p_in_10 br_10 m_tb_10 bg_fff br_10 pointer" v-for="(tool) in tools.data" :key="tool.id"
                 @click="downloadTool(tool.resourcesUrl)">
              <img class="br_5" :src="tool.coverUrl" width="100px" alt="">
              <div class="fs_18 m_tb_5 fw_bold">{{ tool.name }}</div>
              <div class="tool-introduce fs_14 tool-introduce-color" v-html="tool.introduce"></div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  countVisitorsByMenu_api
} from '@/api/mine'
import {
  getResourceClassify,
  getResourceClassifyTree,
  getResourceList
} from '@/api/onlineTeaching/index'
import bannerImg from '@/assets/image/Group 828.png'
import courseImg from '@/assets/image/user-guide.png'
import jiaocaiImg from '@/assets/image/online/Group 904.png'
import toolImg from '@/assets/image/blackboard.png'
import courseBgImg from '@/assets/image/Group 856.png'
import jiaocaiBgImg from '@/assets/image/Group 855.png'
import yilingyiIcon from '@/assets/image/101icon.png'
import zhiwangIcon from '@/assets/image/111.png'
import dakaIcon from '@/assets/image/dakaicon.png'
import luping from '@/assets/image/222.png'
import jxfk from '@/assets/image/jxfk.png'
import xuexiIcon from '@/assets/image/xuexiicon.png'
import nezhaCode from '@/assets/image/nezhaCode.png'
import chaxunCode from '@/assets/image/chaxunCode.png'

export default {
  data() {
    return {
      showCode: false,
      showCodeIndex: '',
      bannerImg,
      courseImg,
      jiaocaiImg,
      toolImg,
      courseBgImg,
      jiaocaiBgImg,
      yilingyiIcon,
      jxfk,
      zhiwangIcon,
      dakaIcon,
      xuexiIcon,
      nezhaCode,
      luping,
      chaxunCode,
      classifyList: [],
      courseCatalogueList: [{
        name: '学段',
        data: []
      }, {
        name: '年级',
        data: []
      }, {
        name: '学科',
        data: []
      }],
      jiaocaiCatalogueList: [{
        name: '学段',
        data: []
      }, {
        name: '年级',
        data: []
      }, {
        name: '学科',
        data: []
      }, {
        name: '版本',
        data: []
      }, {
        name: '册次',
        data: []
      }],
      toolCatalogueList: [],

      classifies: [],
      jiaocaiSelects: [],
      courseSelects: [],

      selectedJiaocai: false,
      selectedCourse: false,
      fullscreenLoading: false

    };
  },
  created() {
    this.fullscreenLoading = true
    getResourceClassify({
      level: 4
    }).then((res) => {
      this.classifyList = res.data.map((item => {
        if (item.remarks1 == 1) return item
      }))

      this.classifyList.forEach((item) => {
        if (item) {
          getResourceClassifyTree({
            id: item.id,
            type: 1
          }).then(res => {
            if (item.name == '电子教材') {
              this.processingJiaocaiData(res.data[0].children, 0)
            } else if (item.name == '教学指导') {
              this.processingCourseData(res.data[0].children, 0)
            } else {
              this.getToolsList(res.data[0].children)
            }
          })
        }
      })
    })
  },
  methods: {
    processingJiaocaiData(data, i) {
      if (!data) return
      let arr = []
      data.forEach(item => {
        if (item.children.length !== 0) {
          arr.push({
            name: item.label,
            id: item.id,
            children: item.children
          })
        } else {
          arr.push({
            name: item.label,
            id: item.id,
            children: []
          })
        }
      })

      this.jiaocaiCatalogueList[i].data = arr
      this.jiaocaiSelects[i] = ''
      i++

      if (data[0].children.length !== 0) {
        this.processingJiaocaiData(data[0].children, i)
      }

      this.fullscreenLoading = false
      this.$forceUpdate()
    },

    processingCourseData(data, i) {
      if (!data) return
      let arr = []
      data.forEach(item => {
        if (item.children.length !== 0) {
          arr.push({
            name: item.label,
            id: item.id,
            children: item.children
          })
        } else {
          arr.push({
            name: item.label,
            id: item.id,
            children: []
          })
        }
      })

      this.courseCatalogueList[i].data = arr
      this.courseSelects[i] = ''
      i++

      if (data[0].children.length !== 0) {
        this.processingCourseData(data[0].children, i)
      }

      this.$forceUpdate()
    },

    jiaocaiClassifyItem_click(id, index) {
      this.selectedJiaocai = true
      this.jiaocaiSelects[index] = id
      if (index + 1 == this.jiaocaiCatalogueList.length) this.toDetails(2)
      this.jiaocaiSelects = this.jiaocaiSelects.slice(0, index + 1)
      this.jiaocaiCatalogueList.map((item, i) => {
        if (i > index + 1) {
          return item.data = []
        }
      })
      this.jiaocaiCatalogueList[index].data.forEach(item => {
        if (item.id === id) {
          if (item.children.length !== 0) {
            this.processingJiaocaiData(item.children, index + 1)
          } else {
            this.jiaocaiCatalogueList.map((jiaocai, i) => {
              if (i > index) {
                return jiaocai.data = []
              }
            })
          }
        }
      })
    },

    courseClassifyItem_click(id, index) {
      this.selectedCourse = true
      this.courseSelects[index] = id
      if (id == '1565668637077979138') this.toDetails(1)
      if (this.courseCatalogueList[index].data[0].children.length == 0) this.toDetails(1)
      this.courseSelects = this.courseSelects.slice(0, index + 1)
      this.courseCatalogueList.map((item, i) => {
        if (i > index + 1) {
          return item.data = []
        }
      })
      this.courseCatalogueList[index].data.forEach(item => {
        if (item.id === id) {
          if (item.children.length !== 0) {
            this.processingCourseData(item.children, index + 1)
          } else {
            this.courseCatalogueList.map((course, i) => {
              if (i > index) {
                return course.data = []
              }
            })
          }
        }
      })
    },

    getToolsList(data) {
      data.forEach((item) => {
        getResourceList({
          contentsId: item.id,
          size: 100,
          current: 1
        }).then(res => {
          this.toolCatalogueList.push({
            name: item.label,
            data: res.data.records
          })
        })
      })
    },

    toDetails(type) {
      if (type == 1) {
        if (this.selectedCourse) {
          countVisitorsByMenu_api({type: 7});
          this.$router.push({
            path: '/onlineTeaching/detail?',
            query: {
              ids: this.courseSelects,
              type
            }
          })
        } else {
          this.$message.warning('请选择分类')
        }
      } else {
        if (this.selectedJiaocai) {
          countVisitorsByMenu_api({type: 6});
          this.$router.push({
            path: '/onlineTeaching/detail?',
            query: {
              ids: this.jiaocaiSelects,
              type
            }
          })
        } else {
          this.$message.warning('请选择分类')
        }
      }
    },
    toolDetail(type) {
      countVisitorsByMenu_api({type: type + 9});
      if (type == 0) {
        window.open('https://ppt.101.com/zy/')
      } else {
        this.showCode = !this.showCode;
        this.showCodeIndex = type
      }
    },
    downloadTool(url) {
      countVisitorsByMenu_api({type: 8});
      window.open(url, '__blank')
    }
  }
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: 0 auto;
}

.bg-other {
  background-color: #ebf4f2;;
}

.box1 .tool-introduce-color {
  padding-bottom: 15px;
}

.phone {
  position: absolute;
  bottom: 6px;
  left: 11px;
}

.title-text {
  line-height: 42px;
  margin-left: 12px;
}

.course-content-box {
  background: url('../../assets/image/Group\ 856.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.jiaocai-content-box {
  background: url('../../assets/image/Group\ 855.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.course-item-box {
  border-bottom: 2px dashed #009de065;
  margin-left: 90px;
  position: relative;
}

.course-item-box:last-child {
  border: none;
}

.jiaocai-item-box {
  border-bottom: 2px dashed #0BB08565;
  margin-left: 90px;
  position: relative;
}

.jiaocai-item-box:last-child {
  border: none;
}

.confirm-button {
  width: 120px;
  height: 40px;
  border-radius: 40px;
  background-color: #b6b6b6;
  line-height: 40px;
  text-align: center;
}

.confirm-button-select {
  background-color: #12C294;
}

.course-selected {
  color: #009CE0;
  font-weight: bold;
}

.jiaocai-selected {
  color: #0BB085;
  font-weight: bold;
}


.tool {
  width: 285px;
  margin-right: 20px;
}

.tool:nth-child(4n) {
  margin-right: 0;
}

.tool-introduce {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.tool-introduce-color {
  color: rgba(0, 0, 0, 0.6);
}
</style>
